import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import stocksSlice from "./stocksSlice";
import plansSlice from "./plansSlice";
import { apiSlice } from "./apiSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    plans: plansSlice,
    stocks: stocksSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
