import { useEffect } from "react";
import { toast } from "react-toastify";

export default function useLoading(
  isFetching = false,
  toastMessage = "Fecthing",
  toastId = "toast-loading"
) {
  useEffect(() => {
    if (isFetching) {
      toast.loading(toastMessage, { toastId: toastId });
    } else {
      toast.dismiss(toastId);
    }
  }, [isFetching, toastId, toastMessage]);
}
