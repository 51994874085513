import React, { useState } from "react";
import {
  colors,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import email from "../../assets/images/email.svg";
import password from "../../assets/images/password.svg";
import eyeClose from "../../assets/images/eyeClose.svg";
import BoldText from "../typography/BoldText";
import validator from "validator";
import ButtonLarge from "./ButtonLarge";
import { useDispatch } from "react-redux";
import {
  loginUser,
  setToken,
  setUser,
  setUserPreferences,
} from "../../redux/userSlice";
import { useNavigate } from "react-router";
import { useLoginUserMutation } from "../../redux/apiSlice";
import { toast } from "react-toastify";
import useLoading from "../../hooks/useLoading";

const SignInForm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useLoading(isLoading, "Signing in", "sign-in");

  const onSubmit = async (data) => {
    try {
      const res = await loginUser(data).unwrap();
      dispatch(setUser(res.user));
      dispatch(setToken(res.token));
      dispatch(setUserPreferences(res.preferences));
      localStorage.setItem("token", res.token);
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message || error?.data || "An error occurred");
    }
  };

  return (
    <article className="w-11/12 lg:w-1/2">
      <div
        className="w-full lg:w-9/12 flex flex-col justify-between rounded-2xl pl-11"
        style={{
          background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          height: "646px",
        }}
      >
        <BoldText
          className="pt-12 h-2/6 text"
          sx={{
            fontSize: "40px",
          }}
        >
          Sign in
        </BoldText>
        <form className="flex flex-col h-4/6" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-11/12">
            <Typography
              color={theme.palette.text.white}
              className=""
              sx={{ fontSize: "13px" }}
            >
              Email
            </Typography>
            <TextField
              variant="standard"
              placeholder="Enter your email address"
              {...register("email", {
                required: "Email is required",
                validate: (value) => {
                  if (!validator.isEmail(value)) return "Email is invalid";
                },
              })}
              fullWidth
              InputProps={{
                style: {
                  borderBottomColor: theme.palette.text.white,
                  borderBottomWidth: "2px",
                  color: theme.palette.text.white,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={email} className="mr-3" alt="Email" />
                  </InputAdornment>
                ),
              }}
            />
            {errors.email && (
              <Typography
                className="text-md mt-1 italic text-red-500"
                role="alert"
              >
                {errors.email.message}
              </Typography>
            )}

            <Typography
              color={theme.palette.text.white}
              className="mt-14"
              sx={{ fontSize: "13px" }}
            >
              Password
            </Typography>
            <TextField
              variant="standard"
              type={hidePassword ? "password" : "text"}
              fullWidth
              placeholder="Enter your password"
              {...register("password", { required: "Password is required" })}
              InputProps={{
                style: {
                  borderBottomColor: theme.palette.text.white,
                  borderBottomWidth: "2px",
                  color: theme.palette.text.white,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={password} className="mr-3" alt="Email" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    onClick={() => setHidePassword(!hidePassword)}
                    position="end"
                  >
                    <img
                      src={eyeClose}
                      className=" w-5 cursor-pointer"
                      alt="Email"
                    />
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <Typography
                className="text-md mt-1 italic text-red-500"
                role="alert"
              >
                {errors.password.message}
              </Typography>
            )}
          </div>

          <ButtonLarge type="submit" className={"text-white hover-btn"}>
            Login
          </ButtonLarge>
        </form>
      </div>
    </article>
  );
};

export default SignInForm;
