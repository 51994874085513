import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: null,
    userPreferences: null,
    users: [],
    selectedUsers: 10,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserPreferences: (state, action) => {
      state.userPreferences = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    resetUser: (state, action) => {
      state.user = null;
      state.token = null;
      state.userPreferences = null;
      state.selectedUsers = 0;
    },
  },
});

export const {
  setUser,
  setToken,
  setUserPreferences,
  resetUser,
  setUsers,
  setSelectedUsers,
} = userSlice.actions;

export default userSlice.reducer;
