import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createStocks,
  fetchAllStockDetails,
  fetchMonthlyStocks,
  fetchStockDetails,
  updateStockDetail,
} from "../api/api";
import { toast } from "react-toastify";
import { updateLoadingToast } from "../utils/helpers";

const initialState = {
  stockDetails: [],
  allStockDetails: [],
  monthlyBuyStocks: [],
  selectedStocks: 10,
};

export const findStock = createAsyncThunk(
  "stocks/findStock",
  async ({ symbol }, { rejectWithValue, dispatch }) => {
    try {
      // Add your code here
      toast.loading(`Fetching stock details for ${symbol}`, {
        toastId: "fetch-stock",
      });
      const res = await fetchStockDetails(symbol);
      dispatch(addStock(res));
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateStockDetails = createAsyncThunk(
  "stocks/updateStockDetail",
  async ({ symbol, isCompliant }, { rejectWithValue, dispatch }) => {
    try {
      // Add your code here
      toast.loading(`Updating stock details for ${symbol}`, {
        toastId: "update-stock",
      });
      const res = await updateStockDetail({ symbol, isCompliant });
      return res;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllStock = createAsyncThunk(
  "stocks/fetchAllStock",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Fetching users", { toastId: "fetch-all-stocks" });
      const res = await fetchAllStockDetails();
      dispatch(setAllStocks(res));
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMonthly = createAsyncThunk(
  "stocks/fetchMonthly",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Fetching monthly stocks", {
        toastId: "fetch-monthly-stocks",
      });
      const res = await fetchMonthlyStocks();
      dispatch(setMonthlyBuyStocks(res));
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createMonthlyStock = createAsyncThunk(
  "stocks/createMonthlyStock",
  async ({ stockDetails }, { rejectWithValue, dispatch }) => {
    try {
      // Add your code here
      toast.loading(`Creating stock for current month`, {
        toastId: "create-stock",
      });
      const res = await createStocks({ stockDetails });
      return res;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const stocksSlice = createSlice({
  name: "stocks",
  initialState,
  reducers: {
    addStock: (state, action) => {
      state.stockDetails.push(action.payload);
    },
    removeStock: (state, action) => {
      state.stockDetails = state.stockDetails.filter(
        (stock) => stock.symbol !== action.payload
      );
    },
    setAllStocks: (state, action) => {
      state.allStockDetails = action.payload;
    },
    setMonthlyBuyStocks: (state, action) => {
      state.monthlyBuyStocks = action.payload;
    },
    setSelectedStocks: (state, action) => {
      state.selectedStocks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findStock.fulfilled, (state, action) => {
      toast.dismiss("fetch-stock");
    });
    builder.addCase(findStock.rejected, (state, action) => {
      updateLoadingToast(
        action?.payload ?? "Error fetching stock",
        "error",
        "fetch-stock"
      );
    });

    builder.addCase(fetchAllStock.fulfilled, (state, action) => {
      toast.dismiss("fetch-all-stocks");
    });

    builder.addCase(fetchAllStock.rejected, (state, action) => {
      updateLoadingToast(
        action?.payload ?? "Error fetching all stocks",
        "error",
        "fetch-all-stocks"
      );
    });

    builder.addCase(updateStockDetails.fulfilled, (state, action) => {
      state.allStockDetails = state.allStockDetails.map((stock) => {
        if (stock.symbol === action.payload.symbol) {
          return {
            ...stock,
            isCompliant: action.payload.isCompliant,
          };
        }
        return stock;
      });
      toast.dismiss("update-stock");
    });
    builder.addCase(updateStockDetails.rejected, (state, action) => {
      updateLoadingToast(
        action?.payload ?? "Error updating stock",
        "error",
        "update-stock"
      );
    });

    builder.addCase(fetchMonthly.fulfilled, (state, action) => {
      toast.dismiss("fetch-monthly-stocks");
    });
    builder.addCase(fetchMonthly.rejected, (state, action) => {
      updateLoadingToast(
        action?.payload ?? "Error fetching monthly stocks",
        "error",
        "fetch-monthly-stocks"
      );
    });

    builder.addCase(createMonthlyStock.fulfilled, (state, action) => {
      updateLoadingToast(
        "Stock created successfully",
        "success",
        "create-stock"
      );
      console.log(action.payload);
      state.monthlyBuyStocks.unshift(action.payload);
      state.stockDetails = [];
    });
    builder.addCase(createMonthlyStock.rejected, (state, action) => {
      updateLoadingToast(
        action?.payload ?? "Error creating stock",
        "error",
        "create-stock"
      );
    });
  },
});

export const {
  addStock,
  removeStock,
  setAllStocks,
  setMonthlyBuyStocks,
  setSelectedStocks,
} = stocksSlice.actions;

export default stocksSlice.reducer;
