import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { truncate } from "../../utils/helpers";
import { StyledTableCell, StyledTableRow } from "../common/Styled";
import { Switch } from "@mui/material";
import AlertDialog from "../common/AlertDialog";
import { useUpdateBrokerMutation } from "../../redux/apiSlice";
import { toast } from "react-toastify";
import useLoading from "../../hooks/useLoading";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const BrokersTable = ({ brokers }) => {
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [selectedBroker, setSelectedBroker] = React.useState({});
  const [updateBroker, { isLoading }] = useUpdateBrokerMutation();
  const [warning, setWarning] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);

  const handleChange = (row) => {
    setSelectedBroker(row);
    setOpen(true);
  };

  useLoading(isLoading, "Updating broker", "update-broker");

  const proceedFunction = async (active) => {
    try {
      const payload = {
        ...selectedBroker,
        active: active,
        displayWarning: warning,
      };
      const res = await updateBroker(payload).unwrap();
      console.log(res);
    } catch (error) {
      toast.error(error?.data?.message || error?.data || "An error occurred");
    }
    setOpen(false);
  };

  const handleWarningChange = (event) => {
    setWarning(event.target.value);
  };

  const handleWarningClick = (row) => {
    setIsEditing(true);
    setSelectedBroker(row);
    setWarning(row?.displayWarning || "");
  };

  return (
    <TableContainer
      component={Paper}
      className="mt-8"
      onClick={() => {
        if (isEditing) {
          setOpenWarning(true);
        }
        setIsEditing(false);
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Slug</StyledTableCell>
            {/* <StyledTableCell>Logo</StyledTableCell> */}
            <StyledTableCell className=" text-center">
              Warning <EditOutlinedIcon fontSize="small" />
            </StyledTableCell>
            <StyledTableCell>Webiste</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brokers?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{truncate(row?.name, 30)}</StyledTableCell>
              <StyledTableCell>{row?.slug}</StyledTableCell>
              {/* <StyledTableCell>{row?.logo}</StyledTableCell> */}
              <StyledTableCell
                className="w-96 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleWarningClick(row);
                }}
              >
                {isEditing && selectedBroker?._id === row?._id ? (
                  <TextField
                    value={warning}
                    onChange={handleWarningChange}
                    size="small"
                    fullWidth
                    multiline
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    rows={3}
                  />
                ) : (
                  row?.displayWarning ||
                  "You can set a warning by clicking here."
                )}
              </StyledTableCell>
              <StyledTableCell>{row?.website}</StyledTableCell>
              <StyledTableCell>
                {row?.active ? "Active" : "Not Active"}
              </StyledTableCell>
              <StyledTableCell align="right">
                <Switch
                  checked={row?.active}
                  onChange={() => handleChange(row)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <AlertDialog
        message={`Are you sure you want to set this broker as ${
          selectedBroker?.active ? "Not Active" : "Active"
        }`}
        header={"Are you sure?"}
        open={open}
        setOpen={setOpen}
        cancelText={"Cancel"}
        proceedText={"Proceed"}
        proceedFn={() => proceedFunction(!selectedBroker.active)}
      />
      <AlertDialog
        message={`Are you sure you want to update this broker.`}
        header={"Are you sure?"}
        open={openWarning}
        setOpen={setOpenWarning}
        cancelText={"Cancel"}
        proceedText={"Proceed"}
        proceedFn={() => proceedFunction(selectedBroker.active)}
      />
    </TableContainer>
  );
};

export default BrokersTable;
