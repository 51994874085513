import { Typography } from "@mui/material";
import React, { useState } from "react";
import Layout from "./Layout";
import BoldText from "../components/typography/BoldText";
import SearchInput from "../components/common/SearchInput";
import { useFetchBrokersQuery } from "../redux/apiSlice";
import BrokersTable from "../components/brokers/BrokersTable";

const Brokers = () => {
  const [query, setQuery] = useState("");
  const { data: brokers } = useFetchBrokersQuery(query);

  return (
    <Layout item={7}>
      <div className="p-10">
        <BoldText className={"text-4xl"}>Brokers</BoldText>
        <div className="mt-6">
          <Typography>Brokers supported by snaptrade</Typography>
        </div>
        <div className="mt-6">
          <SearchInput
            placeholder={"Search Brokers"}
            query={query}
            setQuery={setQuery}
            onSubmit={() => console.log(query)}
          />
        </div>
        <div className="mt-6">
          <BrokersTable brokers={brokers} />
        </div>
      </div>
    </Layout>
  );
};

export default Brokers;
