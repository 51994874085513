import { TextField } from "@mui/material";
import React from "react";

const SearchInput = ({
  placeholder,
  className,
  query,
  setQuery,
  onSubmit,
  minRows = 1,
  style = {
    height: "40px",
    width: "280px",
  },
  multiline = false,
}) => {
  return (
    <div>
      <TextField
        variant="outlined"
        placeholder={placeholder}
        InputProps={{
          style: style,
        }}
        multiline={multiline}
        minRows={minRows}
        maxRows={10}
        value={query}
        className={className}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit?.();
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
