import React, { useState } from "react";
import Layout from "./Layout";
import BoldText from "../components/typography/BoldText";
import SearchBar from "../components/users/SearchBar";
import DataTable from "../components/users/DataTable";
import { useGetUsersQuery } from "../redux/apiSlice";
import { useSelector } from "react-redux";
import useLoading from "../hooks/useLoading";
import useError from "../hooks/useError";

const Users = () => {
  const [query, setQuery] = useState("");
  const { selectedUsers } = useSelector((state) => state.user);
  const { data, isError, error, isFetching } = useGetUsersQuery({
    query,
    count: selectedUsers,
  });

  useLoading(isFetching, "Fetching users", "fetch-users");
  useError(isError, error);

  return (
    <Layout item={1}>
      <div className="p-10">
        <BoldText className={"text-4xl"}>Users</BoldText>
        <SearchBar length={data?.length} query={query} setQuery={setQuery} />
        <DataTable users={data} />
      </div>
    </Layout>
  );
};

export default Users;
