import React from "react";
import Layout from "./Layout";
import BoldText from "../components/typography/BoldText";
import ButtonLarge from "../components/auth/ButtonLarge";
import SearchInput from "../components/common/SearchInput";
import { Typography } from "@mui/material";
import { sendNotifications } from "../api/api";
import { toast } from "react-toastify";

const Notifications = () => {
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  const send = async () => {
    const data = {
      title,
      notification: message,
    };
    const res = await sendNotifications(data);
    if (res) {
      toast.success("Notifications sent successfully");
    }
  };
  return (
    <Layout item={6}>
      <div className="p-10 mt-20">
        <BoldText className={"text-4xl mb-10"}>
          Send Notifications to users
        </BoldText>
        <Typography className="mb-2">Title</Typography>
        <SearchInput
          placeholder={"Title"}
          query={title}
          setQuery={setTitle}
          className={"my-4 lg:my-0 mb-5"}
          style={{ width: "400px", marginBottom: "20px" }}
        />
        <Typography className="mb-2">Message</Typography>
        <SearchInput
          placeholder={"Notification"}
          query={message}
          setQuery={setMessage}
          className={"my-4 lg:my-0"}
          style={{ width: "400px" }}
          multiline={true}
          minRows={3}
        />
        <ButtonLarge onClick={send} width={"150px"}>
          Proceed
        </ButtonLarge>
      </div>
    </Layout>
  );
};

export default Notifications;
