import { useEffect } from "react";
import { toast } from "react-toastify";

export default function useError(isError = false, error) {
  useEffect(() => {
    if (isError) {
      console.error(error);
      toast.error(error.data?.message || error.data || "An error occurred");
    }
  }, [isError, error]);
}
